import * as React from "react"
import { graphql } from "gatsby"

import useIndex from "@hooks/useIndex"

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import JsonLd from "@components/common/JsonLd"
import Top from "@components/index/Top"
import MatchingApp from "@components/index/MatchingApp"
import Party from "@components/index/Party"
import Column from "@components/index/Column"
import Service from "@components/index/Service"

const IndexPage = ({ data }) => {
  const { parties } = useIndex();
  return (
    <Layout needHeader={false} >
      <Seo />
      <JsonLd />
      <Top />
      <MatchingApp />
      <Party parties={parties} />
      <Column data={data.allMicrocmsBlog.edges} />
      <Service />
    </Layout>
  )
}

export const query = graphql`
  {
    allMicrocmsBlog(
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          summary
          meta_description
          content
          category
          publishedAt
          revisedAt
          author {
            name
            description
            image {
              url
            }
          }
          related_blogs {
            id
            title
            hero_image {
              url
            }
            category
            publishedAt
            revisedAt
          }
        }
      }
    }
  }
`

export default IndexPage
